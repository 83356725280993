import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import {
  Container,
  TitlePage,
  CharactericticsWrapper,
  CardFlexWrapper,
  // CardCharac,
  // Text,
  InputWrapper,
  BackButton,
  ButtonSecondary,
  BackgroundCharImage,
  SelectContainer
} from './styles'

import SelectElm from '../../../../components/Select'
import slugify from 'slugify'
import useAllCharacteristics from './hooks'
import { CardCharac, CardLink, CardTitle, CharImage, Text } from '../../interna/InternaGrid/Characteristics/styles'

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: '#fff',
    height: '45px',
    border: state.isFocused ? '1px solid #008200' : '1px solid #000',
    boxShadow: '0 !important',
    borderRadius: 2,
    minWidth: 285,
    maxWidth: 335
  }),
  container: styles => ({ ...styles, marginBottom: '25px' }),
  input: styles => ({ ...styles, color: '#00095B', fontFamily: 'Ford Antenna', fontSize: 16 }),
  placeholder: styles => ({
    ...styles,
    color: '#00095B',
    fontFamily: 'Ford Antenna',
    fontSize: '18px',
    letterSpacing: '1px',
    lineHeight: '24px'
  }),
  singleValue: styles => ({ ...styles })
}

const AllCharacteristics = ({ model, features, envs, clusters, location, clusterPage, basePath, car }) => {
  const { selectedCluster, selectOptions, clusterFeatures, changeQueryString } = useAllCharacteristics({
    features,
    envs,
    clusters,
    location
  })

  const _basePath = basePath ? `/${basePath}` : ''

  const modelYearSlug = slugify(`${model.Year_Model} ${model.Full_Name.toLowerCase().replace(`${model.Year_Model}`, '')}`)

  return (
    <Container>
      <div className="buttonTextWrapper">
        <BackButton>
          <ButtonSecondary>
            <Link
              to={`${_basePath}/${modelYearSlug}`}
              onClick={() => {
                if (window.gtag && car) {
                  window.gtag('event', 'allfeatures-back', {
                    event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                    event_label: window.location.href
                  })
                }
              }}
            >
              <span className="fds-icon fds-color__text--blue">
                <span className="fds-font--ford-icons__chevron-left-blue-with-hover"></span>
              </span>
              <span className="text">{clusterPage.back_button_text}</span>
            </Link>
          </ButtonSecondary>
        </BackButton>

        {/* <TitlePage>Características gerais</TitlePage> */}

        <div className="selectItemWrapper">
          <SelectElm
            value={{ value: selectedCluster.value, label: selectedCluster.label }}
            options={selectOptions}
            styles={colourStyles}
            onChange={option => changeQueryString(option)}
            label={clusterPage.select_cluster_label}
            placeholder=""
            className="selectItem"
            showNoFeeback={true}
            onClick={() => {
              if (window.gtag && car) {
                window.gtag('event', 'interactive-experience', {
                  event_category: `${car[envs.CATEGORY].category_slug.toLowerCase()}-${car.car_name.toLowerCase()}`,
                  event_label: window.location.href
                })
              }
            }}
          />
        </div>
      </div>

      <CharactericticsWrapper>
        {clusterFeatures.map(feat => {
          const media1 = feat?.media_1?.Image_or_Video_Cover?.url
          const callbackLink =
            slugify(feat.Feature_Name.toLowerCase()) + `?char=${selectedCluster.label}&callback=${location.pathname}`

          return (
            <CardCharac>
              <CharImage url={media1 && `https://www.ford360guide.com${media1}`} />
              {/* <Text>
                <div>
                  <p>{feat.Feature_Name}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <Link to={slugify(feat.Feature_Name)}>Ver detalhes</Link>
                  <span
                    className="fds-icon"
                    style={{
                      display: 'block',
                      transform: 'rotate(180deg)',
                      marginLeft: '10px',
                      alignItems: 'center',
                      alignSelf: 'center'
                    }}
                  >
                    <span className="fds-font--ford-icons__chevron-filled-left-path1"></span>
                    <span className="fds-font--ford-icons__chevron-filled-left-path2"></span>
                  </span>
                </div>
              </Text> */}
              <Text>
                <CardTitle>
                  <span>{feat.Feature_Name}</span>
                </CardTitle>
                <CardLink style={{ display: 'flex', alignItems: 'center' }}>
                  <Link style={{ display: 'flex', alignItems: 'center' }} to={callbackLink}>
                    <a className="text">{clusterPage.see_details_feature_card_text}</a>

                    <span
                      className="fds-icon"
                      style={{ display: 'block', textDecoration: 'none', transform: 'rotate(180deg)' }}
                    >
                      <span className="fds-font--ford-icons__chevron-filled-left-path1-with-hover"></span>
                      <span className="fds-font--ford-icons__chevron-filled-left-path2"></span>
                    </span>
                  </Link>
                </CardLink>
              </Text>
            </CardCharac>
          )
        })}
      </CharactericticsWrapper>
    </Container>
  )
}

export default AllCharacteristics
