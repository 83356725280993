import { useState, useEffect } from 'react'
import { capitalize, sortByObjectValue, sortByPriority } from '../../../../helpers'

const useAllCharacteristics = ({ features, envs, clusters, location }) => {
  const urlParams = new URLSearchParams(location.search)

  const [selectedCluster, setSelectedCluster] = useState({
    label: urlParams.get('char') || clusters[0].Cluster_Name,
    value: urlParams.get('char') || clusters[0].Cluster_Name
  })

  const [selectOptions, _setSelectOptions] = useState(
    clusters
      .sort((a, b) => {
        return sortByPriority(a.Cluster_Name, b.Cluster_Name, clusters)
      })
      .map(cluster => {
        return { value: cluster.Cluster_Name, label: cluster.Cluster_Name }
      })
  )
  const [clusterFeatures, setClusterFeatures] = useState([])

  const changeQueryString = selectedCluster => {
    setSelectedCluster(selectedCluster)
  }

  useEffect(() => {
    const filterFeatures = features
      .filter(feature => {
        return feature[envs.FEATURE_CLUSTER].Cluster_Name === selectedCluster.label
      })
      .sort((a, b) => {
        var featureA = a.Feature_Name.toUpperCase()
        var featureB = b.Feature_Name.toUpperCase()
        if (featureA < featureB) return -1
        if (featureA > featureB) return 1

        return 0
      })
      .sort((a, b) => {
        return sortByObjectValue(a, b, 'priority')
      })

    setClusterFeatures(filterFeatures)
  }, [features, selectedCluster, envs])

  return { selectedCluster, selectOptions, clusterFeatures, changeQueryString }
}

export default useAllCharacteristics
