import styled from 'styled-components'

export const ButtonFixed = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  transition: all 0.3s ease-in-out;

  a {
    display: inline-block;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    vertical-align: middle;
    text-decoration: none;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    font-family: FordAntennaCondRegular;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 1px;
    text-transform: none;
    text-align: center;
    padding: 0.5rem 0.75rem 0.5rem;
    width: auto;
    margin-bottom: 0;
    border-radius: 100%;
    border: 2px solid transparent;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    color: #fff !important;
    background-color: ${props => (props.disabled ? '#6e6e6e' : '#00095b')};
    border-color: ${props => (props.disabled ? '#6e6e6e' : '#00095b')};

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    & > span.isContent {
      /* display: none; */
      /* transition: all 0.3s ease-in-out; */
    }

    & span.isContent {
      overflow: hidden;
      transition: ease-in-out 0.2s;
      letter-spacing: -1em;
      opacity: 0;
    }

    .to-top {
      margin-right: 0px;
    }

    &:not(:hover) {
      .isContent {
        /* overflow:hidden; */
      }
      border-radius: 40px;
    }

    &:hover {
      background-color: ${props => (props.disabled ? '#6e6e6e' : '#1700f4')};
      border-color: ${props => (props.disabled ? '#6e6e6e' : '#1700f4')};
      color: #fff;
      transform: ${props => (props.disabled ? 'scale(1)' : 'scale(1.07)')};
      border-radius: 2rem;
      padding: 0.5rem 1.2rem 0.5rem;

      .to-top {
        margin-right: 12px;
      }

      & span.isContent {
        letter-spacing: 0;
        /* transition-delay: 0.2s; */
        opacity: 1;
      }

      & > span.isContent {
        /* display: inline; */
      }
    }

    @media (max-width: 768px) {
      span.isContent {
        overflow: hidden;
        transition: ease-in-out 0.2s;
        letter-spacing: -1em;
        opacity: 0;
      }

      &:hover {
        display: inline-block;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        vertical-align: middle;
        text-decoration: none;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        font-family: FordAntennaCondRegular;
        font-size: 1.125rem;
        line-height: 1.5rem;
        letter-spacing: 1px;
        text-transform: none;
        text-align: center;
        padding: 0.5rem 0.75rem 0.5rem;
        width: auto;
        margin-bottom: 0;
        border-radius: 100%;
        border: 2px solid transparent;
        cursor: ${props => (props.disabled ? 'default' : 'pointer')};
        color: #fff !important;
        background-color: ${props => (props.disabled ? '#6e6e6e' : '#00095b')};
        border-color: ${props => (props.disabled ? '#6e6e6e' : '#00095b')};

        .to-top {
          margin-right: 0px;
        }
        span.isContent {
          overflow: hidden;
          transition: ease-in-out 0.2s;
          letter-spacing: -1em;
          opacity: 0;
        }
      }
    }
  }
`
