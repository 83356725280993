import React from 'react'
import { Template } from '../../../components/template'

import Header from '../../../components/Header'
import Footer from '../../../components/Footer'

import AllCharacteristics from './AllCharacteristics'
import { ToTopButton } from '../../../components/Buttons/ToTop'

const Characteristics = ({ pageContext, location }) => {
  const {
    metatag_title,
    metatag_description,
    metatag_language,
    Privacy_policy_url,
    has_cookie_consent,
    go_to_top_button_text,
    privacy_policy_text,
    privacy_policy_subtext,
    privacy_policy_accept_button_text
  } = pageContext.website.website_info
  const { metatag_subtitle } = pageContext.website.feature_cluster_page

  return (
    <Template
      title={metatag_title}
      description={metatag_description}
      language={metatag_language}
      subtitle={metatag_subtitle}
      has_cookie_consent={has_cookie_consent}
      site={pageContext.envs.SITE}
      privacy_policy_text={privacy_policy_text}
      privacy_policy_subtext={privacy_policy_subtext}
      privacy_policy_accept_button_text={privacy_policy_accept_button_text}
    >
      <Header
        model={pageContext.model}
        header={pageContext.website.header}
        basePath={pageContext.basePath}
        isNotHome
        modelPage={pageContext.modelPage}
        envs={pageContext.envs}
        car={pageContext.car}
      />
      <div style={{ height: 80 }}></div>
      <AllCharacteristics
        features={pageContext.features}
        envs={pageContext.envs}
        model={pageContext.model}
        clusters={pageContext.clusters}
        location={location}
        clusterPage={pageContext.website.feature_cluster_page}
        basePath={pageContext.basePath}
        car={pageContext.car}
      />
      <ToTopButton buttonText={go_to_top_button_text} />
      <Footer privacyPolicyUrl={Privacy_policy_url} footer={pageContext.website.footer} header={pageContext.website.header} />
    </Template>
  )
}

export default Characteristics
