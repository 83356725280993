import React, { useEffect, useState } from 'react'
import { ButtonFixed } from './style'

export const ToTopButton = ({ buttonText }) => {
  const [isHover, setIsHover] = useState(false)

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function scrollToTop() {
    setIsHover(false)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <ButtonFixed style={{ opacity: scrollPosition > 200 ? 1 : 0 }}>
      <a
        style={{ cursor: scrollPosition > 200 ? 'pointer' : 'grab' }}
        onClick={scrollToTop}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <span className="fds-icon">
          <span
            // style={{ marginRight: isHover && '12px' }}
            className="fds-font--ford-icons__chevron-up to-top"
          ></span>
        </span>
        <span
          className="isContent"
          // style={{ opacity: isHover ? 1 : 0, display:  isHover ? 'inline' : 'none'}}
        >
          {buttonText}
        </span>
      </a>
    </ButtonFixed>
  )
}
