const { STRAPI_URL } = require('../constants')

const kebabize = str => {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter
    })
    .join('')
}
const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const capitalize = str => {
  if (typeof str !== 'string') return str

  const lowerStr = str.toLowerCase()
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1)
}

const sortByObjectValue = (a, b, valueName) => {
  try {
    let nameA = a[valueName]
    let nameB = b[valueName]

    if (typeof nameA === 'string' && typeof b === 'string') {
      nameA = nameA.toUpperCase()
      nameB = nameB.toUpperCase()
    }

    if (valueName === 'priority') {
      if (!nameA && nameB) return 1
      if (nameA && !nameB) return -1
      if (nameA > nameB) return 1
      if (nameA < nameB) return -1
    }

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  } catch (e) {
    console.error(`sortByObjectValue: Could not find valueName ${valueName} on object`)
    return 0
  }
}

const sortByPriority = (a, b, clusters) => {
  const clusterA = clusters.find(c => c.Cluster_Name === a)
  const clusterB = clusters.find(c => c.Cluster_Name === b)
  const priorityA = clusterA.priority
  const priorityB = clusterB.priority
  if (!priorityA || !priorityB) return 0

  if (priorityA < priorityB) return -1
  if (priorityA > priorityB) return 1

  return 0
}

const sortByPriorityCars = (carA, carB) => {
  if (!carA.priority || !carB.priority) return 0

  if (carA.priority < carB.priority) return -1
  if (carA.priority > carB.priority) return 1

  return 0
}

const buildImgUrl = imgUrl => {
  return `${STRAPI_URL}${imgUrl}`
}

const forwardParams = (href, exceptions = []) => {
  if (typeof window === 'undefined') return href

  const searchParams = new URLSearchParams(window.location.search)

  exceptions.forEach(exception => {
    searchParams.delete(exception)
  })

  const stringifiedParams = searchParams.toString()

  const hrefHasParams = href.includes('?')

  return `${href}${stringifiedParams ? `${hrefHasParams ? '&' : '?'}${stringifiedParams}` : ''}`
}

module.exports = {
  kebabize,
  camelToSnakeCase,
  capitalize,
  sortByPriority,
  sortByPriorityCars,
  sortByObjectValue,
  buildImgUrl,
  forwardParams
}
